import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-4/5 md:w-52 md:h-36 h-auto space-y-2" }
const _hoisted_2 = { class: "text-center md:text-left" }
const _hoisted_3 = { class: "text-4xl font-bold" }
const _hoisted_4 = { class: "text-base font-light text-gray-800" }
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.num), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (msg, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item text-xs text-gray-500",
          key: index,
          innerHTML: msg
        }, null, 8, _hoisted_6))
      }), 128))
    ])
  ]))
}