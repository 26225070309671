
import { defineComponent } from "vue";
import JcItem from "./JcItem.vue";
import wowmixin from "../../../mixins/wow";
export default defineComponent({
  props: ["imgurl", "num", "title", "items", "index"],
  setup() {
    wowmixin();
  },
  components: { JcItem },
});
