import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-10 md:space-y-0 md:flex flex-col items-center md:items-center md:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jc_item = _resolveComponent("jc-item")!
  const _component_n_image = _resolveComponent("n-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'w-full md:w-1/2 flex justify-center items-center wow animate__animated',
        _ctx.index % 2 ? 'animate__slideInLeft' : 'animate__slideInRight',
        _ctx.index % 2 ? '' : 'order-2',
      ])
    }, [
      _createVNode(_component_jc_item, {
        num: _ctx.num,
        title: _ctx.title,
        items: _ctx.items
      }, null, 8, ["num", "title", "items"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([
        'w-full md:w-1/2 wow animate__animated',
        _ctx.index % 2 ? 'animate__slideInRight' : 'animate__slideInLeft',
      ])
    }, [
      _createVNode(_component_n_image, { src: _ctx.imgurl }, null, 8, ["src"])
    ], 2)
  ]))
}