
import { defineComponent } from "vue";
import ImgCard from "./ImgCard/index.vue";
export default defineComponent({
  components: {
    ImgCard,
  },
  setup() {
    return {
      prodName: "学优宝App",
      msg: "学优宝是一款“双减”政策下创新型AI教学工具，已获得展示方法、播放方法、硬件装置、软件系统等关键节点国家发明专利证书（一种教学视频的展示方法、装置及系统【专利号：ZL 2020 1 0776202.0】、一种用于教学的视频播放方法、装置和系统【专利号：ZL 2020 1 0776199.2】），能实现学生课后跟着自己任课教师学习，同学间相互讨论的“课堂再现”梦想，实现教师照镜子“唤醒”的心灵释放。",
      content: [
        {
          num: "01",
          title: "课前导学",
          items: [
            "任课教师在讲新课前可<b>提前录制</b>预习导学课件，该课件利用<b>重点讲解、问题抛出、任务布置</b>等方式引导学生预习，也可围绕知识点系统<b>自动生成</b>预习导学课件，学生带着问题、任务上课听讲，提高课堂教学效率。",
          ],
          imgurl: require("../../assets/prod-jb/index1.png"),
        },
        {
          num: "02",
          title: "课堂再现",
          items: [
            "任课教师在实时授课时或者讲例题时，通过<b>智能录播</b>系统，将新的知识点进行选择性简单录制、剪辑、生成课件，上传至系统，实现<b>课堂情景再现</b>，学生可以对没听懂的知识点，课后在大屏、平板、电脑、手机等设备上反复观看学习，针对性哪里不会学哪里，为学习、复习减负增效。",
          ],
          imgurl: require("../../assets/prod-jb/index2.png"),
        },
        {
          num: "03",
          title: "课后答疑",
          items: [
            "学生回看课件时，对不理解的问题可以<b>在线提出</b>，同学之间针对问题可进行答疑讨论，讨论的过程中可加深学习印象，任课教师根据同学们讨论情况给予简单<b>解答点评</b>；被老师表扬的同学头像会点亮升级，系统依据教师的点评情况给予<b>积分奖励</b>；积分可在系统兑换虚拟小礼物，荣誉和奖励能够很好的<b>激发学习乐趣</b>，久而久之就养成了主动学习的习惯，同时也改善了传统机械作业的方式。",
          ],
          imgurl: require("../../assets/prod-jb/index3.png"),
        },
        {
          num: "04",
          title: "课后练习",
          items: [
            "平台根据学情分析<b>精准推荐习题</b>，习题按照简易程度进行分类，让学生更加精准练习，系统进行<b>解析纠错批改</b>，批改结果体现在<b>学情报告</b>中，形成学生个人数据库，教师和家长可见。",
          ],
          imgurl: require("../../assets/prod-jb/index4.png"),
        },
        {
          num: "05",
          title: "学情分析报告",
          items: [
            "系统根据<b>学生讨论、教师点评、今日课代表总结、知识点练习</b>等情况自动生成学情报告，形成<b>学生数据库</b>，系统各端都可以看见，为教和学提供数据支撑。",
          ],
          imgurl: require("../../assets/prod-jb/index5.png"),
        },
      ],
    };
  },
});
