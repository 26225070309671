import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 w-full" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "w-full md:w-3/5 py-10 mx-auto" }
const _hoisted_4 = { class: "w-full bg-white py-5 space-y-8" }
const _hoisted_5 = { class: "prefix-item" }
const _hoisted_6 = { class: "px-10 text-sm font-bold text-gray-600" }
const _hoisted_7 = { class: "px-10 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_card = _resolveComponent("img-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "w-full",
      src: require('../../assets/prod-jb/black.png')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.prodName), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.msg), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, index) => {
          return (_openBlock(), _createBlock(_component_img_card, {
            imgurl: item.imgurl,
            num: item.num,
            title: item.title,
            items: item.items,
            index: index,
            key: index
          }, null, 8, ["imgurl", "num", "title", "items", "index"]))
        }), 128))
      ])
    ])
  ]))
}